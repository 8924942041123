/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
import { columnFilterStateUpdater } from '../../../../utils/commonUtil';
import {
  DATA_SOURCE_COMPARISON_VALUE,
  DEFAULT_COL_FILTER_LABEL, DEFAULT_COL_FILTER_VALUE, DEFAULT_OFFSET, DEFAULT_ROW_FETCH_LIMIT, FIN_STATUS_MAPPER, MAX_LIMIT, columnlevelCheckerKeyMapper, columnlevelCheckerKeys, NON_NULL_FILTER_COLUMNS, NULL_FILTER_CHECK,
} from '../../../../utils/constants';
import { columns as checkerDetailscolumns } from '../../../CheckerDetailsTable/CheckerDetailsTableStructure';
import { chipConstants } from '../../ChipLabel/constants';

export const getDataFromSbChild = (data: any, combo: any) => {
  if (combo) {
    const sbRef = combo.sb_reference && combo.sb_reference.length !== 0 ? combo.sb_reference : data.conditions.sb_reference;
    const IdAircraft = combo.id_aircraft_registration && combo.id_aircraft_registration.length !== 0 ? combo.id_aircraft_registration : data.conditions.id_aircraft_registration;
    const ModRef = combo.mod_reference && combo.mod_reference.length !== 0 ? combo.mod_reference : data.conditions.mod_reference;
    const { conditions, ...restData } = data;
    const filterPayload = {
      conditions: {
        ...combo,
        ...conditions,
        sb_reference: sbRef,
        id_aircraft_registration: IdAircraft,
        mod_reference: ModRef,
      },
    };
    const updatedConditions = Object.fromEntries(Object.entries(filterPayload.conditions).filter(([, v]) => v != null && (Array.isArray(v) ? v.length > 0 : [])));
    const payloadObject: any = { ...restData };
    payloadObject.conditions = updatedConditions;
    payloadObject.offset = Number(DEFAULT_OFFSET);
    payloadObject.limit = Number(DEFAULT_ROW_FETCH_LIMIT);
    return payloadObject;
  }
};
export const getDataFromPartChild = (data: any, combo: any) => {
  if (combo) {
    const partFin = combo.fin && combo.fin.length !== 0 ? combo.fin : data.conditions.fin;
    const IdAircraft = combo.id_aircraft_registration && combo.id_aircraft_registration.length !== 0 ? combo.id_aircraft_registration : data.conditions.id_aircraft_registration;
    const partPnr = combo.pnr && combo.pnr.length !== 0 ? combo.pnr : data.conditions.pnr;
    const operator = data.operator_code_icao;
    const filterPayload = {
      conditions: {
        ...combo,
        ...data.conditions,
        id_aircraft_registration: IdAircraft,
        pnr: partPnr,
        fin: partFin,
      },
    };
    const conditions = Object.fromEntries(Object.entries(filterPayload.conditions).filter(([, v]) => v != null && (Array.isArray(v) ? v.length > 0 : [])));
    const payloadArray: any = {};
    payloadArray.conditions = conditions;
    payloadArray.operator_code_icao = operator;
    payloadArray.dataSource = data.dataSource;
    payloadArray.orderBy = data.orderBy;
    payloadArray.offset = Number(DEFAULT_OFFSET);
    payloadArray.limit = Number(DEFAULT_ROW_FETCH_LIMIT);
    return payloadArray;
  }
};
export const getDataFromCheckerDetailsChild = (data: any, combo: any) => {
  if (combo) {
    const finRef = combo.fleet_fin && combo.fleet_fin.length !== 0 ? combo.fleet_fin : data.conditions.fleet_fin;
    const IdAircraft = combo.id_aircraft_registration && combo.id_aircraft_registration.length !== 0 ? combo.id_aircraft_registration : data.conditions.id_aircraft_registration;
    const ataRef = combo.ata && combo.ata.length !== 0 ? combo.ata : data.conditions.ata;
    const operator = data.operator_code_icao;
    const filterPayload = {
      conditions: {
        ...combo,
        ...data.conditions,
        fleet_fin: finRef,
        id_aircraft_registration: IdAircraft,
        ata: ataRef,
      },
    };
    const conditions = Object.fromEntries(Object.entries(filterPayload.conditions).filter(([, v]) => v != null && (Array.isArray(v) ? v.length > 0 : [])));
    const payloadArray: any = {};
    payloadArray.conditions = conditions;
    payloadArray.operator_code_icao = operator;
    payloadArray.offset = Number(DEFAULT_OFFSET);
    payloadArray.orderBy = data.orderBy;
    payloadArray.limit = 1000;
    return payloadArray;
  }
};
const getCommonColumnPayload = (conditionPayload: any, operator: Array<any>, col: any) => {
  const payloadArray: any = {};
  const conditionsArray = Object.fromEntries(Object.entries(conditionPayload).filter(([, v]) => v != null && (Array.isArray(v) ? v.length > 0 : [])));
  payloadArray.conditions = conditionsArray;
  payloadArray.operator_code_icao = operator;
  payloadArray.distinct = true;
  payloadArray.offset = Number(DEFAULT_OFFSET);
  payloadArray.limit = Number(MAX_LIMIT);
  payloadArray.columns = col;
  payloadArray.orderBy = col;
  return payloadArray;
};
/* Filter sb payload mapping dynamically based on the selected options */
export const handleSbFilterColumnPayload = (col: any, filterStates: any, filterValues: any, dispatcher: any, filterthunk: any, queryParams: any, tableState: any) => {
  const aircraftType = filterStates?.sb_filter_selection_configuration?.aircraft_type;
  const operatorName = filterStates?.sb_filter_selection_configuration?.operator_name;
  const selectedMSN = filterStates?.sb_filter_selection_configuration?.selected_msn;
  const selectedMOD = filterStates?.sb_filter_selection_configuration?.selected_mod;
  const selectedSB = filterStates?.sb_filter_selection_configuration?.selected_sb;

  let idAircraftRegistration = [];
  if (col === 'id_aircraft_registration') {
    idAircraftRegistration = selectedMSN;
  } else {
    idAircraftRegistration = filterValues?.id_aircraft_registration && filterValues?.id_aircraft_registration.length !== 0 ? filterValues?.id_aircraft_registration : selectedMSN;
  }

  const conditionsPayload: any = {
    aircraft_type: aircraftType,
    id_aircraft_registration: selectedMSN,
    sb_reference: selectedSB,
    mod_reference: selectedMOD,
  };
  for (const fv in filterValues) {
    if (fv === 'id_aircraft_registration') {
      conditionsPayload[fv] = idAircraftRegistration;
    } else if (fv === 'mod_reference') {
      conditionsPayload[fv] = selectedMOD;
    } else if (fv === 'sb_reference') {
      conditionsPayload[fv] = selectedSB;
    }
  }
  const payloadArray = getCommonColumnPayload(conditionsPayload, new Array(operatorName), new Array(col));
  payloadArray.isSbStatusToggle = tableState.sbStatusViewToggle;
  dispatcher(filterthunk(payloadArray)());
  return payloadArray;
};

export const handleConcurrentFilterPayload = (col: any, filterStates: any, filterValues: any, dispatcher: any, filterthunk: any, queryParams: any) => {
  const dependencyFormulaData = JSON.parse(queryParams.dependencyFormula);
  const sbValues = dependencyFormulaData.dependency_formula.item.map((formula: any) => {
    return formula.sb;
  }).flat(1);
  const { aircraftType, operatorName } = queryParams;
  const selectedMSN = queryParams.msn;
  let idAircraftRegistration = [];
  if (col === 'id_aircraft_registration') {
    idAircraftRegistration = [selectedMSN];
  } else {
    idAircraftRegistration = filterValues?.id_aircraft_registration && filterValues?.id_aircraft_registration.length !== 0 ? filterValues?.id_aircraft_registration : [selectedMSN];
  }

  const finalFilterPayload: any = {
    aircraft_type: aircraftType,
  };

  for (const fv in filterValues) {
    if (fv === 'id_aircraft_registration') {
      finalFilterPayload[fv] = idAircraftRegistration;
    } else if (fv === 'sb_reference') {
      finalFilterPayload[fv] = sbValues;
    }
  }
  const payloadArray = getCommonColumnPayload(finalFilterPayload, new Array(operatorName), new Array(col));
  dispatcher(filterthunk(payloadArray)());
  return payloadArray;
};
/* Filter sb payload mapping dynamically based on the selected options */
export const handlePartFilterColumnPayload = (col: any, filterStates: any, filterValues: any, dispatcher: any, filterthunk: any) => {
  const operatorName = filterStates?.part_filter_selection_configuration?.operator_name;
  const selectedDataSource = filterStates?.part_filter_selection_configuration?.selected_dataSource;
  const selectedMSN = filterStates?.part_filter_selection_configuration?.selected_msn;
  const selectedFin = filterStates?.part_filter_selection_configuration?.selected_fin;
  const selectedPnr = filterStates?.part_filter_selection_configuration?.selected_pnr;

  let idAircraftRegistration = [];
  if (col === 'id_aircraft_registration') {
    idAircraftRegistration = selectedMSN;
  } else {
    idAircraftRegistration = filterValues?.id_aircraft_registration && filterValues?.id_aircraft_registration.length !== 0 ? filterValues?.id_aircraft_registration : selectedMSN;
  }

  const conditionsPayload: any = {
    id_aircraft_registration: selectedMSN,
    fin: selectedFin,
    pnr: selectedPnr,
  };
  for (const fv in filterValues) {
    if (fv === 'id_aircraft_registration') {
      conditionsPayload[fv] = idAircraftRegistration;
    } else if (fv === 'fin') {
      conditionsPayload[fv] = selectedFin;
    } else if (fv === 'pnr') {
      conditionsPayload[fv] = selectedPnr;
    }
  }
  const payloadArray = getCommonColumnPayload(conditionsPayload, new Array(operatorName), new Array(col));
  payloadArray.dataSource = selectedDataSource;
  dispatcher(filterthunk(payloadArray, selectedDataSource)());
  return payloadArray;
};

export const restructureToCombinedColumns = (arrVal: any) => {
  const finalValue = [];
  if (columnlevelCheckerKeys.some((item: any) => item === arrVal[0])) finalValue.push(`acr_${arrVal[0]}`, `mis_${arrVal[0]}`);
  else return arrVal;
  return finalValue;
};
/* Filter checker details payload mapping dynamically based on the selected options */
export const handleCheckerDetailsFilterColumnPayload = (col: any, filterStates: any, filterValues: any, dispatcher: any, filterthunk: any) => {
  const {
    operator_name: operatorName, selected_msn: selectedMSN, selected_fin: selectedFin, selected_ata: selectedAta,
  } = filterStates.checkerDetailFiltersSelected;
  let idAircraftRegistration = [];
  if (col === 'id_aircraft_registration') {
    idAircraftRegistration = selectedMSN;
  } else {
    idAircraftRegistration = filterValues?.id_aircraft_registration && filterValues?.id_aircraft_registration.length !== 0 ? filterValues?.id_aircraft_registration : selectedMSN;
  }

  const conditionsPayload: any = {};
  for (const fv in filterValues) {
    if (fv === 'id_aircraft_registration') {
      conditionsPayload[fv] = idAircraftRegistration;
    } else if (fv === 'fleet_fin' && selectedFin) {
      conditionsPayload[fv] = selectedFin;
    } else if (fv === 'ata' && selectedAta) {
      conditionsPayload[fv] = selectedAta;
    }
  }
  col = new Array(col);
  col = restructureToCombinedColumns(col);
  const payloadArray = getCommonColumnPayload(conditionsPayload, new Array(operatorName), col);
  dispatcher(filterthunk(payloadArray)());
  return payloadArray;
};
export const getSmallerTextValue: any = (value: string, maxLength: number): any => {
  return value.length > maxLength ? `${value.slice(0, maxLength)} ...` : value;
};
/* Initial Select combo box values */
export const initialSelectedComboValues = (FilterState: any) => {
  const itemArray: any = {};
  for (const y in FilterState) {
    for (const x of FilterState[y]) {
      const optValue = x;
      const item = {
        label: optValue,
        value: optValue,
        column: y,
        ext: getSmallerTextValue(optValue, 10),
      };
      if (itemArray[y]) {
        itemArray[y].push(item);
      } else {
        itemArray[y] = [item];
      }
    }
  }
  return itemArray;
};

/* Filter State Count for selected filters Chip */
export const filterStateCount = (FilterState: any, sortBy: any) => {
  let chipCount = 0;
  if (FilterState && Object.values(FilterState).length > 0) {
    for (const x of Object.values(FilterState)) {
      const item: any = x;
      if (item.length > 0) {
        chipCount++;
      }
    }
  }
  if (sortBy) {
    chipCount++;
  }
  return chipCount;
};

export const updateValueArray = (item: any, valueArray: any, key: any, value: any, reasonStatusMapping: any) => {
  if (item === DEFAULT_COL_FILTER_VALUE) {
    valueArray.push(DEFAULT_COL_FILTER_VALUE);
  } else if (key === 'source_status') {
    valueArray.push(reasonStatusMapping.data.status_key_mapper[value]);
  } else if (key === 'reason') {
    valueArray.push(reasonStatusMapping.data.reason_key_mapper[value]);
  } else if (key === 'pnr') {
    const sanitizedPnr = value.replace(/, /g, '_NEXT_');
    valueArray.push(sanitizedPnr);
  } else {
    valueArray.push(value);
  }
};

/* This function restructure the final payload for validate in Column level filter */

export const restructurePayloadConditions = (conditions: any, reasonStatusMapping: any) => {
  conditions.data_source = [DATA_SOURCE_COMPARISON_VALUE.PAYLOAD_VALUE];
  for (const key in conditions) {
    if (columnlevelCheckerKeys.includes(key)) {
      const valueArray: Array<string> = [];
      conditions[key].forEach((item: any) => {
        const value = item.split('#');
        const currentValue = value.length > 1 ? value[1] : value[0];
        updateValueArray(item, valueArray, key, currentValue, reasonStatusMapping);
        if (value[0] === DEFAULT_COL_FILTER_VALUE && conditions[key].length === 1) {
          const defaultNullPnrKey = 'pnr';
          conditions[defaultNullPnrKey] = valueArray;
        } else if (value[0] !== DEFAULT_COL_FILTER_VALUE) {
          conditions[`${key}`] = valueArray;
        }
      });
    }
  }
  return conditions;
};

export const reloadTableData = (response: any, localColumnlevelStateMethods: any, dispatcher: any, columnFilterStateMethods: any, dataFilterConfig: any, payLoad: any) => {
  if (response && !('error' in response)) {
    localColumnlevelStateMethods.setFormState(false);
    localColumnlevelStateMethods.setShowNoData(false);
    columnFilterStateUpdater(dispatcher, columnFilterStateMethods.columnLevelFilterState, dataFilterConfig.state, columnFilterStateMethods.updateColumnFilterState, columnFilterStateMethods.updateColumnFilterPayload, payLoad);
  } else {
    localColumnlevelStateMethods.setShowNoData(true);
  }
};

/* apply filter with selected values - api call */
export const handleApplyFilter = async (localColumnlevelStateMethods: any, columnFilterStateMethods: any, dataFilterConfig: any, dispatcher: any, browserthunk: any, reasonStatusMapping: any) => {
  const isConcurrentToggle = columnFilterStateMethods.columnLevelFilterState.concurrentToggle;
  const isSbStatusToggle = columnFilterStateMethods.columnLevelFilterState.sbStatusViewToggle;
  const payLoad = { ...dataFilterConfig.payload };
  delete payLoad.distinct;
  delete payLoad.columns;
  payLoad.isConcurrentToggle = isConcurrentToggle;
  if ('isCheckerDetailsDataLoading' in columnFilterStateMethods.columnLevelFilterState) {
    payLoad.conditions = restructurePayloadConditions(payLoad.conditions, reasonStatusMapping);
    payLoad.orderBy = restructureToCombinedColumns(payLoad.orderBy);
    const columnsPayload = checkerDetailscolumns;
    if (Object.prototype.hasOwnProperty.call(payLoad.conditions, 'source') === true) {
      if (payLoad.conditions.source.includes('MIS') && payLoad.conditions.source.includes('ACR')) {
        payLoad.columns = checkerDetailscolumns;
      } else if (payLoad.conditions.source.includes('MIS')) {
        payLoad.columns = columnsPayload.filter((columnVal) => { return !columnVal.startsWith('acr_'); });
      } else {
        payLoad.columns = columnsPayload.filter((columnVal) => { return !columnVal.startsWith('mis_'); });
      }
    } else {
      payLoad.columns = checkerDetailscolumns;
    }
  }
  if (!payLoad.userOrderBy) delete payLoad.orderBy;
  delete payLoad.userOrderBy;
  payLoad.isSbStatusToggle = isSbStatusToggle;
  const response = await dispatcher(browserthunk(payLoad, payLoad.dataSource)('columnLevelFilter'));
  reloadTableData(response, localColumnlevelStateMethods, dispatcher, columnFilterStateMethods, dataFilterConfig, payLoad);
};

const arrFlattenSplit = (arr: any) => {
  const orignalValue = [];
  const arrFlatten = [arr];
  for (const i of arrFlatten) {
    orignalValue.push(...i);
  }
  return orignalValue;
};

const arrayWithUniqueObjectValue = (arr: any) => {
  const newArr: any = [];
  const finalUniqueArray = [];
  for (const item of arr) {
    for (const keySource in item) {
      if (!newArr.includes(item[keySource].split('#')[1])) {
        newArr.push(item[keySource].split('#')[1]);
        finalUniqueArray.push(JSON.parse(JSON.stringify(item)));
      } else if (!item[keySource].includes('#')) {
        newArr.push(item[keySource]);
        finalUniqueArray.push(JSON.parse(JSON.stringify(item)));
      }
    }
  }
  return finalUniqueArray;
};

export const restructureResponseForCheckerColumnFilter = (response: any) => {
  const newArray: any = [];
  response = response.map((item: any) => {
    const newObj = { ...item };
    delete newObj.deviation_details;
    return newObj;
  });
  response.forEach((obj: any) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (!columnlevelCheckerKeys.includes(key)) {
        if (typeof value === 'string') {
          const result: any = {};
          result[key] = value;
          newArray.push(result);
        } else if (value && Array.isArray(value)) {
          value.forEach((data) => {
            for (const keySource in data) {
              const arr = arrFlattenSplit(data[keySource]);
              arr.forEach((item: any) => {
                const result: any = {};
                result[columnlevelCheckerKeyMapper[key]] = `${keySource}#${item}`;
                newArray.push(result);
              });
            }
          });
        }
      }
    });
  });
  const finalValue = arrayWithUniqueObjectValue(newArray);
  return finalValue;
};

export const getNullFilterOptions: any = (col: string): any => {
  const optionsArray = [];
  if (!NON_NULL_FILTER_COLUMNS.some((item: any) => item === col)) {
    optionsArray.push({
      label: DEFAULT_COL_FILTER_LABEL, value: DEFAULT_COL_FILTER_VALUE, column: col, ext: DEFAULT_COL_FILTER_LABEL,
    });
  }
  return optionsArray;
};
export const getFilterOptions: any = (col: string, value: string, isChecker: boolean): any => {
  let opt = {
    label: value, value, column: col, ext: getSmallerTextValue(value, 10),
  };
  if (col === 'fin_status') {
    opt = {
      label: chipConstants[FIN_STATUS_MAPPER[value]]?.chipText, value, column: col, ext: chipConstants[FIN_STATUS_MAPPER[value]]?.chipText,
    };
  }
  if (columnlevelCheckerKeys.includes(col) && isChecker && value.includes('#')) {
    const actualLabel = value.split('#')[1];
    opt = {
      label: getSmallerTextValue(actualLabel, 60), value, column: col, ext: getSmallerTextValue(actualLabel, 10),
    };
  }
  return opt;
};

const getComboboxNullOptions: any = (col: any, options: any) => {
  let mapOptValue = [];
  let selectionColumnValue: any;
  if ((options.length || Object.keys(options).length) && options[col] !== undefined) {
    selectionColumnValue = options[col];
    if (selectionColumnValue.some((item: string) => NULL_FILTER_CHECK.includes(item))) {
      mapOptValue = getNullFilterOptions(col);
    }
  }
  return { mapOpt: mapOptValue, selectionColumnValues: selectionColumnValue };
};

/* API Values map with combo box options format - ( Column Level Filter ) */
export const getComboboxOpions: any = (col: any, options: any, isChecker: boolean): any => {
  const { mapOpt, selectionColumnValues } = getComboboxNullOptions(col, options);
  if (selectionColumnValues) {
    for (const optValue of selectionColumnValues) {
      if (optValue && optValue !== DEFAULT_COL_FILTER_VALUE) {
        mapOpt.push(getFilterOptions(col, optValue, isChecker));
      }
    }
  }
  return mapOpt;
};

/* All combo selected values added to state - ( Column Level Filter ) */
export const handleFilterSelection = (selectedValue: any, setFilterValues: any, filterValues: any, setSelectedComboValues: any, col: any, selectedCombovalue: any) => {
  const values: Array<any> = [];
  selectedValue.forEach((item: any) => {
    values.push(item.value);
    setFilterValues({ ...filterValues, [item.column]: values });
  });
  setSelectedComboValues({ ...selectedCombovalue, [col]: selectedValue });
  if (selectedValue.length === 0) {
    setFilterValues({ ...filterValues, [col]: [] });
  }
};
