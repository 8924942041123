/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Col,
  Container, Inline, Modal, Row, Typography,
} from '@airbus/components-react';
import './DisclaimerModalPopup.scss';
import { Warning } from '@airbus/icons/react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { postDisclaimerStatus } from '../../models/disclaimerModal/disclaimerThunk';
import {
  CANCEL, DISCLAIMER_BODY_TEXT, DISCLAIMER_HEADER_TEXT, VALIDATE,
} from '../../utils/constants';
import {
  getConsentFlag, getLastConsentWithExpiration, isConsentValid, populateCheckerFleet, populateCheckerOperators, removeCheckerFilters, removeLastConsentTimestamp, setConsentFlag, setLastConsentTimestamp,
} from '../../utils/commonUtil';
import { loadCheckerFleetData, loadCheckerOperators } from '../../models/checkerFleetModel/checkerFleetThunk';
import { useAppSelector } from '../../store/hooksTypes';
import { setDisclaimerStatus } from '../../utils/commonMethods/generalMethods';

interface DisclaimerInterface {
  isOpen: boolean;
  setDisclaimerIsOpen: any;
  userIcao: string | undefined;
  dispatcher: any;
}
const DisclaimerModalPopup = ({
  isOpen, setDisclaimerIsOpen, userIcao, dispatcher,
}: DisclaimerInterface) => {
  const navigate = useNavigate();
  const [applyBtnDisabler, setapplyBtnDisabler] = useState(false);
  const checkerState = useAppSelector((state) => state.checkerFleet);
  useEffect(() => {
    const fleetPayload: any = {
      conditions: {},
      operator_code_icao: checkerState.fleet_view_filter_selected?.selected_operator,
      orderBy: ['registration_number'],
      sortType: 'ASC',
    };
    if (!isEmpty(checkerState.fleet_view_filter_selected?.selected_operator) && isOpen) {
      populateCheckerFleet(dispatcher, loadCheckerFleetData, fleetPayload, setDisclaimerIsOpen, checkerState);
    }
  }, [checkerState.fleet_view_filter_selected?.selected_operator]);
  const updateDisclaimer = () => {
    setapplyBtnDisabler(true);
    // populate the operators based on consent validity
    const consentExpiration = getLastConsentWithExpiration();
    const consentFlag = getConsentFlag();
    if (isConsentValid(consentExpiration, consentFlag)) {
      populateCheckerOperators(dispatcher, loadCheckerOperators, checkerState);
    } else {
      const disclaimerUpdatePayload: DisclaimerStatusAPIPayloadType = {
        operator_code_icao: new Array(String(userIcao)),
      };
      removeCheckerFilters();
      removeLastConsentTimestamp();
      dispatcher(postDisclaimerStatus(disclaimerUpdatePayload)()).then(() => {
        setDisclaimerStatus();
        setDisclaimerIsOpen(false);
        // store consent date and consent flag
        setLastConsentTimestamp(new Date());
        setConsentFlag('true');
      });
    }
  };
  const cancelDisclaimer = () => {
    setConsentFlag('false');
    removeCheckerFilters();
    removeLastConsentTimestamp();
    navigate('/SignOut');
  };
  return (
    <Container>
      <Modal
        aria-labelledby="modal-title"
        className="disclaimer-popup-popup-container"
        open={isOpen}
      >
        <div className="modal-container">
          <Container className="disclaimer-container">
            <div className="header-section">
              <Inline spacing="1-x">
                <Row>
                  <Col>
                    <Warning color="orange" fontSize={25} />
                  </Col>
                  <Col>
                    <Typography className="disclaimer-header-text">{DISCLAIMER_HEADER_TEXT}</Typography>
                  </Col>
                </Row>
              </Inline>
            </div>
            <div className="modal-container-outer">
              <div className="modal-container-card">
                <div className="modal-content">
                  {DISCLAIMER_BODY_TEXT}
                </div>
              </div>
            </div>
            <Inline spacing="2-x" align="center" className="disclaimer-buttons">
              <Button size="small" data-testid="cancel-disclaimer" onClick={cancelDisclaimer}>{CANCEL}</Button>
              <Button tabIndex={-3} size="small" data-testid="apply-disclaimer" disabled={applyBtnDisabler} variant="primary" className="apply-button" onClick={updateDisclaimer}>{VALIDATE}</Button>
            </Inline>
          </Container>
        </div>
      </Modal>
    </Container>
  );
};
export default DisclaimerModalPopup;
