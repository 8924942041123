import {
  Breadcrumb, BreadcrumbItem, Card, Container, Link,
  Typography,
} from '@airbus/components-react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import './UploadFliHistory.scss';
import { HISTORY_TABLE_DATA, HOME, UPLOAD_HISTORY } from '../../utils/constants';
import Table from '../Shared/Table/Table';
import { LOCALES } from '../../assets/locale';
import AppHeader from '../Shared/AppHeader/AppHeader';
import { historyTableColumns } from './UploadFliHistoryTableStructure';

const UploadFliHistory: React.FC = function UploadFliHistory() {
  return (
    <Container className="bg-coolgrey-10 p-2 history-table-container">
      <div>
        <AppHeader appName={LOCALES} />
        <Outlet />
      </div>
      <Card className="history-card-container">
        <Breadcrumb className="breadcrumb-container-history">
          <BreadcrumbItem>
            <Link href="/" className="home-page">
              {HOME}
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem current>{UPLOAD_HISTORY}</BreadcrumbItem>
        </Breadcrumb>
        <Typography variant="large" className="text-content">{UPLOAD_HISTORY}</Typography>
      </Card>
      <Container className="history-page-container checker-details-container" id="historyContainer">
        <Table
          data-testid="history-data-table"
          tableData={HISTORY_TABLE_DATA}
          headerColumns={historyTableColumns}
          showChangeViewButton={false}
          showExportButton={false}
          showFilterButton={false}
          tableClassName="history-data-table"
          loadingupdate={false}
          showPagination={false}
        />
      </Container>
    </Container>
  );
};
export default UploadFliHistory;
