import {
  Button,
  Col,
  ColorModeProvider, Container, IconButton, Popover, Row,
} from '@airbus/components-react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InsertDriveFile, Restore, Upload } from '@airbus/icons/react';
import UseOutsideClick from '../Shared/OutsideClick/UseOutsideClick';
import {
  UPLOAD_NEW_FILE,
  UPLOAD_HISTORY,
} from '../../utils/constants';
import './UploadFile.scss';
import { nswcFliUrlLink } from '../../utils/commonUtil';

const UploadFile: React.FC = function UploadFile() {
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const onOpenHandler = () => {
    setOpen(!open);
  };

  UseOutsideClick(buttonRef, () => {
    setOpen(false);
  });

  return (
    <Container className="upload-file-Popover-Container">
      <IconButton
        ref={buttonRef}
        onClick={onOpenHandler}
        aria-haspopup="dialog"
        className="file-button"
        data-testid="icon-click"
      >
        <InsertDriveFile className="file-icon" />
      </IconButton>
      <ColorModeProvider mode="light">
        <Popover open={open} referenceNode={buttonRef.current} className="upload-file-popover" data-testid="upload-file-popover" placement="top">
          <Container>
            <Row>
              <Col xxs={7}>
                <Button icon={<Upload className="icon-color" />} className="upload-file-button" aria-label="upload-file-button" data-testid="upload-file-button" onClick={nswcFliUrlLink}>{UPLOAD_NEW_FILE}</Button>
              </Col>
              <Col xxs={7}>
                <Button icon={<Restore className="icon-color" />} onClick={() => navigate('/uploadHistory')} data-testid="upload-history-button" className="upload-file-button">{UPLOAD_HISTORY}</Button>
              </Col>
            </Row>
          </Container>
        </Popover>
      </ColorModeProvider>
    </Container>
  );
};
export default UploadFile;
