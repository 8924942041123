export const USER_PERMISSIONS_INIT_STATE: UserPermissionState = {
  permissionList: [],
  moduleList: [],
  isLoading: false,
  errorMessage: '',
  userAllowed: false,
  userScope: '',
  userIcao: '',
  permissionObj: [],
};

export const PERMISSION_PRECEDENCE = ['checker', 'browser', 'acm'];
export const AUTH_ENTRY_SLICE = 'authEntrySlice';
export const GET_USER_PERMISSIONS = 'getUserPermissions';
export const MODULE_PERMISSION_MAP:PermissionMap = {
  BROWSER: '/browser',
  CHECKER: '/checker',
  ACM: '/acm',
};
