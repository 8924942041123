/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button, Col, Container, Inline, Modal, Row, Spinner, Typography,
} from '@airbus/components-react';
import './CheckerFinEditComponent.scss';
import { Close } from '@airbus/icons/react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import {
  DESC_SORT_TYPE,
  CANCEL,
  MANAGE_FIN_STATUS,
  MANAGE_FIN_STATUS_DESCRIPTION,
  DEFAULT_ROW_FETCH_LIMIT,
  SAVE,
  FIN_HISTORY_PAYLOAD_DEFAULT_COLUMNS,
  FIN_HISTORY_PAYLOAD_DEFAULT_ORDERBY,
} from '../../utils/constants';
import { clearFleetDetailSelectedFin, setFinChangesStateReducer, setFinEditModalState } from '../../models/checkerDetailsModel/checkerDetailsSlice';
import { loadCheckerFinHistoryDetails } from '../../models/checkerDetailsModel/checkerDetailsThunk';
import CheckerFinInlineModelComponent from './CheckerFinInlineModelComponent';
import { handleFinStatusSubmit } from './CheckerFinEditModelUtils';

const CheckerFinEditModalPopup = () => {
  const CheckerFinEditModalPopupDispatcher = useAppDispatch();
  const checkerDataTableState = useAppSelector((state) => state.displayCheckerDetailsTable);
  const {
    selectedFin, finEditModalState, checkerDetailFiltersSelected,
    finStatusHistory, finChangesState, isFinStatusUpdateLoading, isClearSelection, paginationData,
  } = checkerDataTableState;
  const [isDisable, setDisable] = useState<boolean>(false);

  useEffect(() => {
    // enable or Disable the save button based on comment
    const isAnyCommentNotFilled = finChangesState.find((item: CheckerSelectedFinDataType) => {
      return (item.comment === '' || item.comment === undefined);
    });
    setDisable(finChangesState.length === 0 || isAnyCommentNotFilled !== undefined);
  }, [finChangesState]);

  useEffect(() => {
    const isLoadHistory = selectedFin.some((item: any) => item.user_id);
    if (finEditModalState && isLoadHistory) {
      const loadFinHistoryPayload = {
        operator_code_icao: new Array(checkerDetailFiltersSelected.operator_name),
        conditions: {
          data: selectedFin.map((item: any) => ({ id_aircraft_registration: item.id_aircraft_registration, fleet_fin: item.fleet_fin })),
        },
        columns: FIN_HISTORY_PAYLOAD_DEFAULT_COLUMNS,
        orderBy: FIN_HISTORY_PAYLOAD_DEFAULT_ORDERBY,
        limit: Number(DEFAULT_ROW_FETCH_LIMIT),
        sortType: DESC_SORT_TYPE,
      };
      CheckerFinEditModalPopupDispatcher(loadCheckerFinHistoryDetails(loadFinHistoryPayload)());
    }
  }, [finEditModalState]);
  const handlerSubmitArgs = {
    checkerDetailFiltersSelected, checkerDataTableState, finChangesState, CheckerFinEditModalPopupDispatcher, paginationData, isClearSelection,
  };

  return (
    <Container>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fin-edit-modal-popup-container"
        id="fin-edit-detail-popup"
        open={finEditModalState}
      >
        <div className="modal-container">
          {isFinStatusUpdateLoading ? <Spinner size="medium" className="fin-edit-spinner" /> : <Typography />}
          <Container className={isFinStatusUpdateLoading ? 'fin-edit-disable-container' : 'fin-edit-container'}>
            <div className="header-section">
              <Row className="header-label">
                <Col xxs={6} className="header-title">
                  <Inline spacing="1-x">
                    <Typography variant="medium">{MANAGE_FIN_STATUS}</Typography>
                  </Inline>
                </Col>
                <Col xxs={1}>
                  <Close
                    className="modal-close-icon"
                    data-testid="modal-close"
                    onClick={
                      () => {
                        CheckerFinEditModalPopupDispatcher(setFinEditModalState(!finEditModalState));
                        CheckerFinEditModalPopupDispatcher(clearFleetDetailSelectedFin(!isClearSelection));
                        CheckerFinEditModalPopupDispatcher(setFinChangesStateReducer([]));
                      }
                    }
                  />
                </Col>
              </Row>
              <Row className="header-label">
                <Col>
                  <Typography variant="medium" className="header-label-description">
                    {MANAGE_FIN_STATUS_DESCRIPTION}
                  </Typography>
                </Col>
              </Row>
            </div>
            <CheckerFinInlineModelComponent selectedFin={selectedFin} finStatusHistory={finStatusHistory} checkerDataTableState={checkerDataTableState} CheckerFinEditModalPopupDispatcher={CheckerFinEditModalPopupDispatcher} />
            <Inline spacing="half-x" align="end" className="inline-button dataSelectionPrimary">
              <Button
                size="medium"
                variant="secondary"
                className="reset-button"
                onClick={
                  () => {
                    CheckerFinEditModalPopupDispatcher(setFinEditModalState(!finEditModalState));
                    CheckerFinEditModalPopupDispatcher(clearFleetDetailSelectedFin(!isClearSelection));
                    CheckerFinEditModalPopupDispatcher(setFinChangesStateReducer([]));
                  }
                }
                data-testid="modal-cancel"
              >
                {CANCEL}
              </Button>
              <Button size="medium" className={isDisable ? 'save-button-disabled' : 'save-button'} disabled={isDisable} data-testid="modal-validate" onClick={() => handleFinStatusSubmit(handlerSubmitArgs)}>{SAVE}</Button>
            </Inline>
          </Container>
        </div>
      </Modal>
    </Container>
  );
};
export default CheckerFinEditModalPopup;
