/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React from 'react';
import { Link } from '@airbus/components-react';
import { useAppSelector } from '../../store/hooksTypes';
import {
  AIR_NAVX_LINKS, DOCTYPE_IPC, DOCTYPE_IPD, EXTERNAL,
} from '../../utils/constants';
import './AirNavxCell.scss';
import { CustomPopover } from '../Shared/CustomPopover/CustomPopover';

/* Airnavx query param optimization for ac type */
export const AircraftTypeParam = (params: any) => {
  const arr: string[] = [];
  params.forEach((it: any) => {
    arr.push(`actype:${it}`);
  });
  return `${arr.join(';')}`;
};
/* Airnavx query param optimization for msn , ata */
export const modifyQueryStringMSN = (input: any) => {
  const extractedMsn = input?.split(' ')[0];
  return extractedMsn?.replace(/([A-Z])0+(\d)/, '$1$2');
};
export const modifyQueryStringFIN = (input: any) => {
  return input?.includes('#') ? input?.split('#')[0] : input;
};
const doctypeFn = (input: string) => {
  if (input === 'A350') {
    return DOCTYPE_IPD;
  }
  return DOCTYPE_IPC;
};
export const airnavxUrl = (row: any, column: any, aircraftValue: string) => {
  let msnValue = modifyQueryStringMSN(row?.values?.id_aircraft_registration);
  const finValue = modifyQueryStringFIN(row?.values?.fin);
  const aircraftType = aircraftValue.split(':')[1];
  const docValue = doctypeFn(aircraftType);
  if (aircraftType === 'A330') {
    msnValue = msnValue?.replace(/^L/, 'F');
  }
  if (aircraftType === 'A380') {
    msnValue = msnValue?.replace(/^R/, 'L');
  }
  if (column?.id === 'fin') {
    return `/search/text?wc=customization:${row?.original?.operator_code_icao};fin:${finValue};${aircraftValue};doctype:${docValue};tailNumber:${msnValue}`;
  }
  if (column?.id === 'pnr') {
    return `/search/text?wc=customization:${row?.original?.operator_code_icao};pnMeta:${row.values.pnr};${aircraftValue};doctype:${docValue};tailNumber:${msnValue}`;
  }
  if (column?.id === 'as_allowed_pnr') {
    return `/allowable?wc=${aircraftValue};customization:${row.original.operator_code_icao};tailNumber:${msnValue};fin:${finValue}`;
  }
};
export const airNavxFunc = (value: any, row: any) => {
  if (row?.tableName === 'reason-details-table') {
    return value;
  }
  const tablebody: Element = document.querySelector('[class$=-body]') as Element;
  return (
    <CustomPopover value={value} scrollTrigger={tablebody}>
      <div className="elipseColumn" data-testid="tooltipValue"><div className="cell-values">{value}</div></div>
    </CustomPopover>
  );
};
/* CMI integration with Airnavx */
export const AirNavxCell = ({ row, value, column }: any) => {
  const { userScope } = useAppSelector((state: any) => state.authEntrySlice);
  const featureToggleConfig: any = useAppSelector((state) => state.featureToggleDetails?.data);
  const filterStates = useAppSelector((state) => state.popUpFilter);
  const { checkerDetailFiltersSelected, checkerDetailsFilterOptions } = useAppSelector((state) => state.displayCheckerDetailsTable);
  let acType: any;
  if (row?.tableName === 'reason-details-table') {
    if (checkerDetailFiltersSelected?.aircraft_type?.length) {
      acType = checkerDetailFiltersSelected.aircraft_type;
    } else {
      acType = checkerDetailsFilterOptions.ac_type;
    }
  } else {
    acType = filterStates?.part_filter_selection_configuration?.aircraft_type;
  }
  const aircraftValue = AircraftTypeParam(acType);
  const airnavxUrlReformmed = airnavxUrl(row, column, aircraftValue);
  let headerInfo = '';
  headerInfo = `${AIR_NAVX_LINKS[0]}${airnavxUrlReformmed}`;
  if (EXTERNAL[0] === userScope) {
    headerInfo = `${AIR_NAVX_LINKS[1]}${airnavxUrlReformmed}`;
  }
  return (
    featureToggleConfig?.airnavx === 'true'
      ? (
        <Link href={headerInfo} target="_blank" className="redirect-airnavx">
          <p className="concurr-msn-sb">
            {airNavxFunc(value, row)}
          </p>
        </Link>
      )
      : (
        airNavxFunc(value, row)
      )
  );
};
