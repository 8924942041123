/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-unresolved */
import {
  Button,
  Col,
  ColorModeProvider, Container, Divider, IconButton, Popover, Row, Typography,
} from '@airbus/components-react';
import { useRef, useState } from 'react';
import { Help } from '@airbus/icons/react';
import UseOutsideClick from '../Shared/OutsideClick/UseOutsideClick';
import {
  USER_GUIDE, AIRBUS_NOTICE, APP_FOOTER_VERSION, AIRBUS_NOTICE_LINK,
} from '../../utils/constants';
import './UserHelp.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { userGuide } from '../../models/appConfigModel/appConfigThunk';
import { USER_GUIDE_FILE_NAME } from '../../models/appConfigModel/constants';
import { getDisclaimerStatus } from '../../utils/commonMethods/generalMethods';
import { isRequiredForEnv } from '../../utils/commonUtil';

const UserHelp: React.FC = function UserHelp() {
  const buttonRef = useRef(null);
  const dispatcher = useAppDispatch();
  const [open, setOpen] = useState(false);
  const userHelpGuide = useAppSelector((state) => state.app);

  const handleSettingsClick = async (clickSource: string) => {
    if (clickSource === USER_GUIDE) {
      const actionResult = await dispatcher(userGuide(USER_GUIDE_FILE_NAME)());
      const newGuideLink = actionResult?.payload?.download_file_link || userHelpGuide.download_file_link;
      window.open(newGuideLink);
    } else {
      window.open(AIRBUS_NOTICE_LINK, 'WindowName', 'noopener');
    }
  };
  const onOpenHandler = () => {
    setOpen(!open);
  };

  UseOutsideClick(buttonRef, () => {
    setOpen(false);
  });
  return (
    <Container className="user-Help-Popover-Container">
      <IconButton
        ref={buttonRef}
        disabled={isRequiredForEnv(['pro', 'val']) ? !getDisclaimerStatus() : false}
        onClick={onOpenHandler}
        aria-haspopup="dialog"
        className="menu-button"
        data-testid="help-click"
      >
        <Help className="help-Style" />
      </IconButton>
      <ColorModeProvider mode="light">
        <Popover open={open} referenceNode={buttonRef.current} className="user-menu-popover" data-testid="usermenu-popover" placement="top">
          <Container>
            <Row>
              <Col xxs={7}>
                <Button className="user-menuguide" aria-label="user-menuguide" data-testid="userguide" onClick={() => handleSettingsClick(USER_GUIDE)}>{USER_GUIDE}</Button>
              </Col>
              <Col xxs={7}>
                <Button onClick={() => handleSettingsClick(AIRBUS_NOTICE)} data-testid="airbusnotice" className="user-menuguide">{AIRBUS_NOTICE}</Button>
              </Col>
            </Row>
          </Container>
          <Divider className="user-divider" />
          <Typography className="app-version" data-testid="version">{APP_FOOTER_VERSION}</Typography>
        </Popover>
      </ColorModeProvider>
    </Container>
  );
};
export default UserHelp;
