/* eslint-disable @typescript-eslint/no-explicit-any */
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import {
  CONCURRENT_TABLE_ACTION, CONCURRENT_DATATABLE_SLICE, CONCURRENT_TABLE_FILTER_ACTION, CONCURRENT_TABLE_FILTER_THUNK,
} from './constants';
import { LOAD_POST_API_METHOD, LOAD_SB_BROWSE_API_PATH } from '../../utils/config/apiConfig';
import { CustomThunkAction } from '../../utils/thunkUtils/thunkTypes';

interface LoadConcurrentDataProps {
  operatorName: any;
  aircraftType: any;
  ata: string;
  conf: string;
  mod: string;
  mp: string;
  msn: string;
  sbRef: string;
  status: string;
  dependencyFormula: any;
  isConcurrentToggle?: boolean;
}
const createCustomThunkCall = (action: CustomThunkAction, body: BodyPayLoadSBData) => {
  const apiRequestObj = {
    path: LOAD_SB_BROWSE_API_PATH,
    method: LOAD_POST_API_METHOD,
    body,
  };
  return createCustomAsyncThunk(action, apiRequestObj);
};
export const loadConcurrentData: any = (msnData: LoadConcurrentDataProps) => {
  const customAction = {
    sliceName: CONCURRENT_DATATABLE_SLICE,
    actionType: CONCURRENT_TABLE_ACTION,
  };
  const dependencyFormula = msnData ? JSON.parse(msnData.dependencyFormula) : {};
  const sbRefrence = dependencyFormula.dependency_formula && dependencyFormula.dependency_formula.item ? dependencyFormula.dependency_formula.item.map((item: any) => item.sb) : [];
  const aircraftType = msnData ? msnData.aircraftType : '';
  const operatorName = msnData ? msnData.operatorName : '';
  const msn = msnData ? msnData.msn : '';
  const isConcurrentToggle = msnData ? msnData.isConcurrentToggle : false;
  const apiRequestObj = {
    path: LOAD_SB_BROWSE_API_PATH,
    method: LOAD_POST_API_METHOD,
    body: {
      conditions: {
        sb_reference: sbRefrence?.flat(1),
        aircraft_type: aircraftType,
        id_aircraft_registration: [msn],
      },
      operator_code_icao: [operatorName],
      offset: 0,
      limit: 1000,
      isConcurrentToggle,

    },
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const loadCuncurrentFilterData: any = (payloadBody: BodyPayLoadSBData) => {
  const Action = {
    sliceName: CONCURRENT_DATATABLE_SLICE,
    actionType: CONCURRENT_TABLE_FILTER_ACTION,
  };
  return createCustomThunkCall(Action, payloadBody);
};

export const loadCuncurrentFilterThunk: any = (payloadBody: BodyPayLoadSBData) => {
  const customAction = {
    sliceName: CONCURRENT_DATATABLE_SLICE,
    actionType: CONCURRENT_TABLE_FILTER_THUNK,
  };
  return createCustomThunkCall(customAction, payloadBody);
};
