/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import {
  Col, ComboBox, Container, Inline, Row, Spinner, Typography,
} from '@airbus/components-react';
import _ from 'lodash';
import { LiveHelp } from '@airbus/icons/react';
import {
  ACM_EXPORT_TYPE,
  ACM_ORDER_BY,
  ACM_REPORT_DOWNLOAD,
  DESC_SORT_TYPE,
  AC_FAMILY,
  AIRCRAFT_SUB_TYPE,
  AIRCRAFT_TYPE,
  ASCENDING, COMBOBOX_PLACEHOLDER, OPERATOR, ACM_AIRCRAFT_DETAILS_COLUMNS, ACM_MSN, ACM_REGISTRATION_NUMBER,
  USER_SCOPE_NSWC,
} from '../../../../utils/constants';
import './AcmTab.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooksTypes';
import {
  loadOperators, loadSbAircraftDetails,
} from '../../../../models/popupFilterModel/popupFilterThunks';
import AcmTypeSelection from '../../../AcmFilter/RadioButton/AcmTypeSelection';
import ExportButton from '../../Export/ExportButton';
import { loadSpinner } from '../../../../utils/commonMethods/modalPopupMethods';
import { setHelpModelToggle } from '../../../../models/helpModel/helpModelSlice';
import { HybridComboBox } from '../../HybridComboBox/HybridComboBox';
import {
  getAcAndAcSubTypesForBrowser, getAircraftTypeACM, getMsnForSbBrowser,
} from '../../../../models/popupFilterModel/popupFilterSlice';
import { getAcSubTypeList, registerPostHogProperties } from '../../../../utils/commonUtil';

const initAcmFilterConfig: AcmFilterKeys = {
  operator_name: '',
  ac_family: '',
  aircraft_type: [],
  aircraft_subtype: [],
  selected_msn: [],
};

const AcmTab: React.FC = function AcmTab() {
  const [currentAcmFilterConfig, setCurrentAcmFilterConfig] = React.useState<AcmFilterKeys>(initAcmFilterConfig);
  const filterStates = useAppSelector((state) => state.popUpFilter);
  const [acSubTypeOptions, setAcSubTypeOptions] = React.useState([]);
  const { selectedRadioButton } = useAppSelector((state: any) => state.app);
  const { isLoading } = useAppSelector((state) => state.authEntrySlice);
  const { userScope } = useAppSelector((state) => state.authEntrySlice);
  const featureToggleConfig: any = useAppSelector((state) => state.featureToggleDetails?.data);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadOperators()('acm'));
  }, [dispatch]);
  registerPostHogProperties({
    ICAO_CODE: currentAcmFilterConfig?.operator_name?.toString(),
  });
  const handleOperatorSelection = (selectedAcmOperator: any) => {
    registerPostHogProperties({
      ICAO_CODE: selectedAcmOperator?.toString(),
    });
    setCurrentAcmFilterConfig((prevState) => {
      return {
        ...prevState,
        operator_name: selectedAcmOperator,
        aircraft_type: [],
        aircraft_subtype: [],
        ac_family: '',
        selected_msn: [],
      };
    });
  };
  const handleAcFamiySelection = (selectedAcmAcFamily: any) => {
    setCurrentAcmFilterConfig((prevState) => {
      return {
        ...prevState,
        ac_family: selectedAcmAcFamily,
        aircraft_type: initAcmFilterConfig.aircraft_type,
        aircraft_subtype: initAcmFilterConfig.aircraft_subtype,
        selected_msn: [],
      };
    });
    const acTypePayload: any = {
      aircraft_program_code: selectedAcmAcFamily.value,
      operator_code_icao: currentAcmFilterConfig?.operator_name,
      acm_selection_columns: filterStates.acm_selection_columns,
    };
    dispatch(getAircraftTypeACM(acTypePayload));
  };
  const handleAcSelection = (selectedAcmAcType: Array<any>) => {
    setCurrentAcmFilterConfig((prevState) => {
      return {
        ...prevState,
        aircraft_type: selectedAcmAcType,
        aircraft_subtype: initAcmFilterConfig.aircraft_subtype,
        selected_msn: filterStates?.acm_msns,
      };
    });
    const msnPayload: any = {
      aircraft_types: selectedAcmAcType,
      aircraft_family: [],
      aircraft_program_code: currentAcmFilterConfig?.ac_family,
      operator_code_icao: currentAcmFilterConfig?.operator_name,
      acm_selection_columns: filterStates.acm_selection_columns,
      selectedTab: 'acm',
    };
    dispatch(getMsnForSbBrowser(msnPayload));
  };
  const handleMsnApply = (selectedMsn: any) => {
    setCurrentAcmFilterConfig((prevState) => {
      return {
        ...prevState,
        selected_msn: selectedMsn,
      };
    });
  };
  const handleAcSubtypeSelection = (selectedAcSubtype: Array<any>) => {
    setCurrentAcmFilterConfig((prevState) => {
      return {
        ...prevState,
        aircraft_subtype: selectedAcSubtype,
        selected_msn: filterStates?.acm_msns,
      };
    });
    const msnPayload: any = {
      aircraft_types: currentAcmFilterConfig?.aircraft_type,
      aircraft_family: selectedAcSubtype,
      aircraft_program_code: currentAcmFilterConfig?.ac_family,
      operator_code_icao: currentAcmFilterConfig?.operator_name,
      acm_selection_columns: filterStates.acm_selection_columns,
      selectedTab: 'acm',
    };
    dispatch(getMsnForSbBrowser(msnPayload));
  };
  useEffect(() => {
    if (filterStates.acm_operators_list?.length) {
      const listOfOperators = filterStates.acm_operators_list[0];
      setCurrentAcmFilterConfig((prevState) => {
        return ({
          ...prevState,
          operator_name: listOfOperators,
          aircraft_type: [],
          aircraft_subtype: [],
          ac_family: '',
          selected_msn: [],

        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStates?.acm_operators_list]);
  useEffect(() => {
    const selectedAcType = currentAcmFilterConfig?.aircraft_type;
    const acsubMapping = filterStates?.acm_operators_acsubType_mapping;
    // Need to filter all the AC sub types based on AC types.
    const filteredACSubTypeOptions: any = !_.isEmpty(selectedAcType) && !_.isEmpty(filterStates?.acm_operators_acsubType_mapping)
      ? getAcSubTypeList(selectedAcType, acsubMapping) : [];
    if (!_.isEmpty(filteredACSubTypeOptions)) {
      setAcSubTypeOptions(filteredACSubTypeOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAcmFilterConfig?.aircraft_type]);
  useEffect(() => {
    if (featureToggleConfig?.nswc === 'true' && userScope === USER_SCOPE_NSWC) {
      setCurrentAcmFilterConfig((prevState) => {
        return {
          ...prevState,
          selected_msn: [],
        };
      });
    } else {
      setCurrentAcmFilterConfig((prevState) => {
        return {
          ...prevState,
          selected_msn: filterStates?.acm_msns,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStates?.acm_msns, userScope]);
  const handleColumnsForACM = (selectedOperator: any) => {
    if (!_.isEmpty(filterStates?.acm_selection_columns)
      && filterStates?.acm_selection_columns[selectedOperator]) {
      const bodyPayload: any = {
        operator_code_icao: selectedOperator,
        acm_selection_columns: filterStates?.acm_selection_columns,
        selectedTab: 'acm',
      };
      dispatch(getAcAndAcSubTypesForBrowser(bodyPayload));
      return;
    }
    const payloadParamsForOperatorMsn: allColumnsBodyPayload = {
      conditions: {},
      operator_code_icao: new Array(selectedOperator),
      sortType: ASCENDING,
      distinct: true,
      columns: ACM_AIRCRAFT_DETAILS_COLUMNS,
      orderBy: ['id_aircraft_registration'],
    };
    dispatch(loadSbAircraftDetails(payloadParamsForOperatorMsn)({ selectedOperator, tab: 'acm' }));
  };
  useEffect(() => {
    if (!_.isEmpty(currentAcmFilterConfig?.operator_name)) handleColumnsForACM(currentAcmFilterConfig?.operator_name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAcmFilterConfig?.operator_name]);
  return (
    <>
      {' '}
      {isLoading ? (
        <Spinner size="medium" className="user-api-loading" />
      ) : (
        <Container className="acm-container">
          <div className="acm-body-container">
            <Row className="acm-row-container">
              <Col className="acm-col-container" xs={3} sm={5}>
                <Inline spacing="1-x">
                  <Typography variant="medium" className="acm-report-text">{ACM_REPORT_DOWNLOAD}</Typography>
                  <LiveHelp className="help-icon" data-testid="acm-help-icon" onClick={() => { dispatch(setHelpModelToggle({ helpModalState: true, sourceOfHelpClick: featureToggleConfig?.nswc === 'true' && userScope === USER_SCOPE_NSWC ? 'acmFilterNswcHelpIcon' : 'acmFilterHelpIcon' })); }} />
                </Inline>
              </Col>
            </Row>
            <Row className="acm-row-container">
              <Col className="acm-col-container" xs={3} sm={2}>
                <Typography variant="small" className="acm-filter-text">
                  {OPERATOR}
                  <span className="required-fields">*</span>
                </Typography>
              </Col>
              <Col xxs={1}>
                {loadSpinner(filterStates?.isFilterLoading, false, '', filterStates?.acm_operators_list?.length)}
              </Col>
              <Col xxs={4} md={4}>
                <ComboBox
                  aria-label="Select Operator name"
                  placeholder={COMBOBOX_PLACEHOLDER}
                  value={currentAcmFilterConfig?.operator_name}
                  data-testid="operator-selector"
                  disabled={!filterStates?.acm_operators_list?.length}
                  onChange={(value: any) => handleOperatorSelection(value)}
                  options={filterStates?.acm_operators_list}
                  getOptionLabel={(option) => option}
                  getOptionValue={(option) => option}
                  hideClear
                />
              </Col>
            </Row>
            <Row className="acm-row-container">
              <Col className="acm-col-container" xxs={3} sm={2}>
                <Typography variant="small" className="acm-filter-text">
                  {AC_FAMILY}
                  <span className="required-fields">*</span>
                </Typography>
              </Col>
              <Col xxs={1}>
                {loadSpinner(filterStates?.isFilterLoading, currentAcmFilterConfig.operator_name, currentAcmFilterConfig.ac_family)}
              </Col>
              <Col xxs={4} md={4}>
                <ComboBox
                  aria-label="Select AC Family"
                  placeholder={COMBOBOX_PLACEHOLDER}
                  value={currentAcmFilterConfig?.ac_family}
                  data-testid="ac-family-selector"
                  disabled={(_.isEmpty(currentAcmFilterConfig?.operator_name)) || filterStates?.isFilterLoading === true}
                  onChange={(value: any) => handleAcFamiySelection(value)}
                  options={filterStates?.acm_operators_ac_family}
                  hideClear
                />
              </Col>
            </Row>
            <Row className="acm-row-container">
              <Col className="acm-col-container" xxs={3} sm={3}>
                <Typography variant="small" className="acm-filter-text">
                  {AIRCRAFT_TYPE}
                  <span className="required-fields">*</span>
                </Typography>
              </Col>
              <Col xxs={4} md={4}>
                <ComboBox
                  aria-label="Select AC Type"
                  placeholder={currentAcmFilterConfig?.aircraft_type?.length ? '' : COMBOBOX_PLACEHOLDER}
                  value={currentAcmFilterConfig?.aircraft_type}
                  data-testid="ac-selector"
                  disabled={(_.isEmpty(currentAcmFilterConfig?.ac_family)) || filterStates?.isFilterLoading === true}
                  onChange={(value: any) => handleAcSelection(value)}
                  options={filterStates?.acm_operators_ac_mapping}
                  getOptionLabel={(option) => option}
                  getOptionValue={(option) => option}
                  hideClear
                  multiple
                />
              </Col>
            </Row>
            <Row className="acm-row-container">
              <Col className="acm-col-container" xxs={3} sm={3}>
                <Typography variant="small" className="acm-filter-text">
                  {AIRCRAFT_SUB_TYPE}
                </Typography>
              </Col>
              <Col xxs={4} md={4}>
                <ComboBox
                  aria-label="Select AC SubType"
                  placeholder={currentAcmFilterConfig?.aircraft_subtype?.length ? '' : COMBOBOX_PLACEHOLDER}
                  value={currentAcmFilterConfig?.aircraft_subtype}
                  data-testid="ac-subtype-selector"
                  disabled={(_.isEmpty(currentAcmFilterConfig?.aircraft_type)) || filterStates?.isFilterLoading === true}
                  onChange={(value: any) => handleAcSubtypeSelection(value)}
                  options={acSubTypeOptions}
                  getOptionLabel={(option) => option}
                  getOptionValue={(option) => option}
                  hideClear
                  multiple
                />
              </Col>
            </Row>
            <Row className="acm-row-container">
              <Col className="acm-col-container" xs={3} sm={3}>
                <Typography variant="small" className="acm-filter-text">{ACM_MSN}</Typography>
                <Typography variant="small" className="acm-filter-text">
                  {ACM_REGISTRATION_NUMBER}
                  {featureToggleConfig?.nswc === 'true' && userScope === USER_SCOPE_NSWC
                    && <span className="required-fields">*</span>}
                </Typography>
              </Col>
              <Col xxs={6} md={6} className="msn-col">
                <HybridComboBox
                  state={filterStates}
                  dispatcher={dispatch}
                  isSearchDisabled={false}
                  id="acmMsn"
                  data-testid="msn-selector"
                  disabled={(_.isEmpty(currentAcmFilterConfig?.aircraft_type)) || filterStates?.isFilterLoading === true}
                  options={filterStates?.acm_msns}
                  handleApply={handleMsnApply}
                  selectedOptions={currentAcmFilterConfig?.selected_msn}
                  hybridComboClassName="hybrid-acm-popover"
                  maxSelections={featureToggleConfig?.nswc === 'true' && userScope === USER_SCOPE_NSWC ? 5 : undefined}
                />
              </Col>
            </Row>

            <Row className="acm-row-container">
              <Col className="acm-col-container" xxs={3} md={3}>
                <Typography
                  variant="small"
                  className="acm-filter-text"
                >
                  ACM Type:
                </Typography>
              </Col>
              <Col xxs={4} md={4}>
                <AcmTypeSelection />
              </Col>
            </Row>
            <ExportButton
              selectedExport={{
                ...currentAcmFilterConfig,
                selected_export_acm_type: selectedRadioButton,
                sortType: DESC_SORT_TYPE,
                orderBy: ACM_ORDER_BY,
              }}
              exportType={ACM_EXPORT_TYPE}
            />
          </div>
        </Container>
      )}
    </>
  );
};

export default AcmTab;
