export const historyTableColumns = [
  {
    accessor: 'file_name',
    id: 'file_name',
    align: 'left',
    Header: 'File name',
    width: 1,
    disableSortBy: true,
  },
  {
    accessor: 'upload_date',
    id: 'upload_date',
    align: 'left',
    Header: 'Upload date',
    width: 60,
    disableSortBy: true,
  },
  {
    accessor: 'fli_status',
    id: 'fli_status',
    align: 'left',
    Header: 'FLI/CMI status',
    width: 80,
    disableSortBy: true,
  },
];
