import toastSlice from '../models/toastModel/toastSlice';
import concurrentTableSlice from '../models/concurrentTableModel/concurrentTableSlice';
import dataTableSlice from '../models/sbDataTableModel/sbDataTableSlice';
import helpModelSlice from '../models/helpModel/helpModelSlice';
import { popupFilterSlice } from '../models/popupFilterModel/popupFilterSlice';
import appSlice from '../models/appConfigModel/appConfigSlice';
import downloadStatusSlice from '../models/downloadStatusModel/downloadStatusSlice';
import partdataTableSlice from '../models/partDataTableModel/partDataTableSlice';
import checketFleetSlice from '../models/checkerFleetModel/checkerFleetSlice';
import checkerDetailsSlice from '../models/checkerDetailsModel/checkerDetailsSlice';
import authSlice from '../models/authModel/authSlice';
import { reasonStatusMappingSlice } from '../models/reasonStatusMappingModel/reasonStatusMappingSlice';
import disclaimerDetailsSlice from '../models/disclaimerModal/disclaimerSlice';
import reportStatusSlice from '../models/reportStatusModel/reportStatusSlice';
import featureToggleDetailsSlice from '../models/featureToggleModal/featureToggleSlice';
import emailNotificationSlice from '../models/emailNotificationModel/emailNotificationSlice';

export const rootReducer = {
  [dataTableSlice.name]: dataTableSlice.reducer,
  [popupFilterSlice.name]: popupFilterSlice.reducer,
  [concurrentTableSlice.name]: concurrentTableSlice.reducer,
  [helpModelSlice.name]: helpModelSlice.reducer,
  [toastSlice.name]: toastSlice.reducer,
  [downloadStatusSlice.name]: downloadStatusSlice.reducer,
  [appSlice.name]: appSlice.reducer,
  [partdataTableSlice.name]: partdataTableSlice.reducer,
  [checketFleetSlice.name]: checketFleetSlice.reducer,
  [checkerDetailsSlice.name]: checkerDetailsSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [reasonStatusMappingSlice.name]: reasonStatusMappingSlice.reducer,
  [disclaimerDetailsSlice.name]: disclaimerDetailsSlice.reducer,
  [reportStatusSlice.name]: reportStatusSlice.reducer,
  [featureToggleDetailsSlice.name]: featureToggleDetailsSlice.reducer,
  [emailNotificationSlice.name]: emailNotificationSlice.reducer,
};
